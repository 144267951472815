import logo from '../../assets/img/entry-page/goodness-gracious-logo@2x.png'
import image from '../../assets/img/entry-page/march-madness-hero@2x.jpg'
import Socials from './Socials'
import pdf from '../../assets/pdfs/GG-March-2023-Giveaway-terms-and-conditions.pdf'

const Thankyou = () => {
    return (
        <section className='_thankyou-section'>
            <div className='_grid _grid-2'>
                <div className='_column'>
                    <div className='_copy'>
                        <img className='_logo' src={logo} alt="Goodness Gracious" />
                        <h1 className='_heading-1'>Thank you for your entry!</h1>
                        <p>This competition <strong>closes on the 14th of April 2023</strong>. Keep in touch, connect with us on social media!</p>
                        <p className='_terms'><a href={pdf} download="GG March 2023 Giveaway Ts and Cs" target="_blank" rel="noreferrer">View the Terms & Conditions</a></p>
                        <Socials />
                        <p><a href="https://www.goodnessgracious.co.za/" target="_blank" rel="noreferrer" style={{textDecoration: 'underline'}}>Visit our website</a> and browse through our variety of home-cooked feel-good frozen meals.</p>
                    </div>
                </div>
            </div>
            <div className='_image' style={{backgroundImage: `url(${image})`}} />
        </section>
    )
}

export default Thankyou