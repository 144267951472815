import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Checkbox2
} from './fields'

import scrollToFirstError from './SmoothScrollToErrorFields.js';
import pdf from '../../assets/pdfs/GG-March-2023-Giveaway-terms-and-conditions.pdf'

class EntryForm extends React.Component {

    state= {
        button: 'Send'
    }

    renderErrors = () => {

        if(typeof this.props.errors === 'string'){
            return <div className="_error-message">{this.props.errors}</div>
        }

        return Object.values(this.props.errors).map((item, index) => {  

            if(!item[0])
            return <></>
            
            if(typeof item[0] === 'string' && item[0].length > 1){
                return <div className="_error-message" key={index}>{item[0]}</div>
            }

            return <></>
        })
    }

    renderSubmitButton = () => {
        if(this.state.button === 'Sending'){
            // return <img src={loader} alt="Entering" width="60" />
            return <button className={`_button _submit-button`} type="submit">Submitting</button>
        } else {
            return <button className={`_button _submit-button`} type="submit">Submit</button>
        }
    }

    onSubmit = formValues => {
        this.setState({
            button: 'Sending'
        })
        this.props.onSubmit(formValues)
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-contact">
                <h2 className='_heading-1' style={{textAlign: 'center',marginTop: '0'}}>Refer 5 friends and stand a chance to <span className='_gold'>WIN BIG!</span></h2>
                {'name' && <div name={`position-name`} />}
                {'surname' && <div name={`position-surname`} />}
                <div className="_form-row">
                    <Field name="name" type="text" component={Input} label="Name*" />
                    <Field name="surname" type="text" component={Input} label="Surname*" />
                </div>
                {'email' && <div name={`position-email`} />}
                {'phone' && <div name={`position-phone`} />}
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Email Address*" />
                    <Field name="phone" type="text" component={Input} label="Mobile Number*" />
                </div>

                <h2 className="_heading-1" style={{textAlign: 'center'}}>Terms & Conditions</h2>
                {'over_18' && <div name={`position-over_18`} />}
                {'terms_accepted' && <div name={`position-terms_accepted`} />}
                <div className="_form-row">
                    <Field name="over_18" component={Checkbox2} label="I am over 18*" />
                    <Field name="terms_accepted" component={Checkbox2} label="Accept Terms & Conditions*" link={pdf} />
                </div>

                <h2 className="_heading-1" style={{textAlign: 'center'}}>Refer 5 friends</h2>
                <div className="_form-row">
                    <Field name="referal_1_fullname" type="text" component={Input} label="Full Name*" />
                    <Field name="referal_1_email" type="email" component={Input} label="Email*" />
                </div>
                <div className="_form-row">
                    <Field name="referal_2_fullname" type="text" component={Input} label="Full Name*" />
                    <Field name="referal_2_email" type="email" component={Input} label="Email*" />
                </div>
                <div className="_form-row">
                    <Field name="referal_3_fullname" type="text" component={Input} label="Full Name*" />
                    <Field name="referal_3_email" type="email" component={Input} label="Email*" />
                </div>
                <div className="_form-row">
                    <Field name="referal_4_fullname" type="text" component={Input} label="Full Name*" />
                    <Field name="referal_4_email" type="email" component={Input} label="Email*" />
                </div>
                <div className="_form-row">
                    <Field name="referal_5_fullname" type="text" component={Input} label="Full Name*" />
                    <Field name="referal_5_email" type="email" component={Input} label="Email*" />
                </div>

                {
                    this.props.errors && (
                        <div className="_form-row">
                            <div className="_error-group">{this.renderErrors()}</div>
                        </div>
                    )
                }

                <div className="_form-group" style={{marginTop: '0', textAlign: 'center'}}>
                    {this.renderSubmitButton()}
                </div>
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.name){
        errors.name = "You must enter your name"
    }

    if(!formValues.surname){
        errors.surname = "You must enter your surname"
    }

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }

    if(!formValues.phone){
        errors.phone = "You must enter your mobile number"
    }

    if(!formValues.over_18){
        errors.over_18 = "You must confirm that you are over 18 years old"
    }

    if(!formValues.terms_accepted){
        errors.terms_accepted = "You accept our terms and conditions"
    }

    return errors
}

export default reduxForm({
    form: 'competition',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(EntryForm)