import { useEffect } from 'react'
import EntryForm from '../forms/EntryForm'
import {connect} from 'react-redux'
import {
    createEntry,
    resetForms
} from '../../actions'
import {Navigate} from 'react-router-dom'
import Socials from './Socials'

//images
import logo from '../../assets/img/entry-page/goodness-gracious-logo@2x.png'
import image from '../../assets/img/entry-page/march-madness-hero@2x.jpg'
import thumbnail1 from '../../assets/img/entry-page/gallery-01.jpg'
import thumbnail2 from '../../assets/img/entry-page/gallery-02.jpg'
import thumbnail3 from '../../assets/img/entry-page/gallery-03.jpg'
import thumbnail4 from '../../assets/img/entry-page/gallery-04.jpg'
import halaal from '../../assets/img/Halaal_LOGO_-_GG.webp'

const Competition = props => {

    useEffect(() => {
        return props.resetForms
    },[props.resetForms])

    const scrollToPosition = (anchorTag, space) => {
        setTimeout(() => {
          const element = document.querySelector(anchorTag)
          if(element){
            let topPos = element.getBoundingClientRect().top + window.pageYOffset
            if(space){
                topPos = element.getBoundingClientRect().top + window.pageYOffset - space
            } 
            
            window.scrollTo({
                top: topPos, // scroll so that the element is at the top of the view
                behavior: 'smooth' // smooth scroll
            })
          }
        }, 300);
      }

    const onSubmit = formValues => {
        props.createEntry(formValues)
    }

    if(props.entry.success){
        return <Navigate to="/goodnessgracious/thankyou" />
    }

    return (
        <>
        <section className='_top-section'>
            <div className='_grid _grid-2'>
                <div className='_column'>
                    <div className='_copy'>
                        <img className='_logo' src={logo} alt="Goodness Gracious" />
                        <h1 className='_heading-1'>Join the <span className='_gold'>March Madness</span> celebration and WIN BIG with <span className='_gold'>Goodness Gracious!</span></h1>
                        <p>To enter, simply refer 5 friends and stand a chance to <strong>WIN</strong> a <span className='_gold'>Goodness Gracious</span> gift card to the value of R5 000.00</p>
                        <a href="#enter-here" className='_button' onClick={() => scrollToPosition("#enter-here")}>Enter Here</a> 
                    </div>
                </div>
            </div>
            <div className='_image' style={{backgroundImage: `url(${image})`}} />
        </section>
        <section className='_section-2'>
            <div className='_grid _grid-4'>
                <div className='_column'>
                    <img className='_image' src={thumbnail1} alt="gallery 1" />
                </div>
                <div className='_column'>
                    <img className='_image' src={thumbnail2} alt="gallery 2" />
                </div>
                <div className='_column'>
                    <img className='_image' src={thumbnail3} alt="gallery 3" />
                </div>
                <div className='_column'>
                    <img className='_image' src={thumbnail4} alt="gallery 4" />
                </div>
            </div>
        </section>
        <section className='_form-section' id="enter-here">
            <div className='_grid'>
                <div className='_column'>
                    <div className='_panel'>
                        <EntryForm onSubmit={onSubmit} />
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div className='_grid _grid-3'>
                <div className='_column'>
                    <a href="https://www.goodnessgracious.co.za/" target="_blank" rel="noreferrer">
                        <img src={logo} alt="goodness gracious" className='_logo' />
                        <p>© 2023 Goodness Gracious.</p>
                    </a>
                </div>
                <div className='_column'>
                    <Socials />
                </div>
                <div className='_column'>
                    <img src={halaal} alt="Halaal HT 5547" className='_halaal' />
                    <p>All meals Halaal certified.</p>
                </div>
            </div>
        </footer>
        </>
    )
}

const mapStateToProps = state => {
    return {
        entry: state.entryState
    }
}

export default connect(mapStateToProps, {
    createEntry,
    resetForms
})(Competition)