import facebook from '../../assets/img/entry-page/iconmonstr-facebook-4.svg'
import instagram from '../../assets/img/entry-page/iconmonstr-instagram-14.svg'

const Socials = () => {
    return (
        <div className='_socials'>
            <a href="https://www.facebook.com/GoodnessGraciousFood" target="_blank" rel="noreferrer">
                <img src={facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/goodnessgracioussa" target="_blank" rel="noreferrer">
                <img src={instagram} alt="instagram" />
            </a>
        </div>
    )
}

export default Socials