import admin from '../apis/admin'
import {
    RESET_FORMS,
    CREATE_ENTRY,
    CREATE_ENTRY_FAILED
} from './types'

export const resetForms = () => {
  return (dispatch) => {
      dispatch({
          type: RESET_FORMS
      })
  }
}

export const createEntry = formValues => async dispatch => {

  try {

      const response = await admin.post('entries', formValues)

      dispatch({
          type: CREATE_ENTRY,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_ENTRY_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}