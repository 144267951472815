import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Competition from './Competition'
import CompetitionThankyou from './Competition/Thankyou'

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                    <Route path="/" element={<Competition />} />
                    <Route path="/goodnessgracious" element={<Competition />} />
                    <Route path="/goodnessgracious/thankyou" element={<CompetitionThankyou />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App