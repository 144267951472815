import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import entryReducer from './entryReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
}

const rootReducer = combineReducers({
    entryState: entryReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)