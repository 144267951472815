import {
    RESET_FORMS,
    CREATE_ENTRY,
    CREATE_ENTRY_FAILED
} from '../actions/types'

const initialState = {}

const entryReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ENTRY:
            return {
                success: action.payload
            }
        case CREATE_ENTRY_FAILED:
            return {
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default entryReducer